@import "nlib/config";

.newContactWidgetDropDown {
  position: absolute;
  min-width: 15rem;
  padding: $contentDefaultIndent * 2;
  .button {
    flex: 1;
    font-weight: 500;
    svg {
      margin-right: $contentDefaultIndent / 2;
      font-size: 1rem;
    }
  }
}

[data-mobile] {
  .newContactWidgetDropDown {
    .form {
      padding: 0 $contentDefaultIndent * 2 $contentDefaultIndent * 2;
    }
  }
}

@import "config";

.selectContactInput {
  display: flex;
  flex: 1;
  width: 100%;
  &:not(:first-child) {
    .contactInput {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.withButton {
    .contactInput {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .contactInput {
    &:global(.is) {
      &-valid {
        padding-right: 1rem;
        background-image: none;
      }
    }
    &.highlight {
      border-color: $uiHighlightColor !important;
      box-shadow: 0 5px 11px rgba($uiHighlightColor, 0.1) !important;
    }
    &.warning {
      border-color: $uiWarningColor !important;
      box-shadow: 0 5px 11px rgba($uiWarningColor, 0.1) !important;
    }
  }
  .contactButton {
    position: relative;
    z-index: 10;
    flex: none;
    width: 3.5em;
    margin-left: -1px;
    padding: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &.highlight {
      border-color: $uiHighlightColor;
    }
    &:hover {
      &.highlight {
        background-color: $uiHighlightColor !important;
      }
    }
    &:not(:hover) {
      background-color: $uiWhiteColor;
      &.highlight {
        svg {
          color: $uiHighlightColor;
        }
      }
    }
    &,
    &:focus {
      box-shadow: none !important;
    }
    &[data-contact] {
      background-color: lighten($uiPositiveColor, 55%);
      color: $uiPositiveColor;
      &[data-toggle] {
        &:hover {
          border-color: $uiNegativeColor;
          background-color: $uiNegativeColor;
          color: $uiWhiteColor;
          svg {
            &:not([data-clear]) {
              display: none;
            }
            &[data-clear] {
              display: inline;
            }
          }
        }
      }
      &:hover {
        background-color: $uiPositiveColor;
        color: $uiWhiteColor;
      }
    }
    svg {
      font-size: 1.3em;
      &[data-clear] {
        display: none;
      }
    }
  }
  .newContactWidget {
    width: 22em;
    margin-left: -1px;
    padding: $contentDefaultIndent;
    font-size: $uiDesktopFontSize;
    :global(.input) {
      &-group {
        &-text {
          width: 5em;
          line-height: 1;
        }
        select {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }
    .title {
      margin-bottom: $contentDefaultIndent;
    }
    .buttonWrapper {
      display: flex;
      justify-content: center;
      margin-top: $contentDefaultIndent * 2;
      button + button {
        margin-left: $contentDefaultIndent;
      }
    }
  }
}

@import "nlib/config";

.datePickerInput {
  .clear {
    display: flex;
    position: absolute;
    top: 50%;
    right: $contentDefaultIndent;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(0, -50%);
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

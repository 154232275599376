@import "nlib/config";

.includeExclude {
  display: flex;
  flex-direction: column;
  max-height: 240px;
  overflow: hidden;
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $uiNeutralColor;
    &:active {
      background-color: $uiHighlightColor;
    }
  }
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 2px;
    background-color: $uiBorderColor;
  }
  .input {
    input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .row {
    display: flex;
    flex: auto;
    overflow: hidden;
  }
  .col {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $contentDefaultIndent * 1.5;
    overflow: hidden;
    border: 1px solid $uiBorderColor;
    border-top: none;
    border-bottom-left-radius: $uiBorderRadius;
    .head {
      display: flex;
      margin-bottom: $contentDefaultIndent * 1.5;
      padding-bottom: $contentDefaultIndent * 1.5;
      border-bottom: 1px solid $uiBorderColor;
      font-size: 0.75rem;
      white-space: nowrap;
      .button {
        svg {
          font-size: 16px;
        }
        &:not([disabled]) {
          cursor: pointer;
          &:hover {
            filter: brightness(0.5);
          }
        }
      }
    }
    + .col {
      border-left: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: $uiBorderRadius;
    }
  }
  .content {
    overflow: auto;
  }
  .item {
    display: flex;
    align-items: center;
    padding: $contentDefaultIndent / 2 0;
    svg {
      min-width: 1rem;
      margin-right: 0.3em;
      font-size: 1rem;
    }
    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:not([disabled]) {
      cursor: pointer;
      &:hover {
        filter: brightness(0.5);
      }
    }
  }
  .placeholder {
    color: $uiPlaceholderColor;
  }
}

@import "nlib/config";

.postSignUp {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .title {
    font-size: 1.563rem;
    line-height: 2rem;
  }
  &.billingIssue {
    .content {
      .row {
        .col {
          p {
            margin-bottom: 2em;
          }
        }
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 62em;
    height: fit-content;
    margin: auto;
    padding: $contentDefaultIndent * 2;
    text-align: center;
    .card {
      height: 100%;
      border-radius: $contentDefaultIndent;
      background-color: $uiWhiteColor;
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
      .block {
        padding: 3em 2em;
      }
    }
    .description {
      font-size: 0.875rem;
      h4 {
        margin-top: 0;
        color: $uiBlackColor;
      }
      p {
        margin-top: 2em;
        margin-bottom: 0;
        padding: 0 6em;
      }
      .billingIssue {
        font-weight: 500;
        svg {
          color: $uiWarningColor;
          font-size: 1rem;
        }
      }
    }
    .row {
      display: flex;
      margin: 2em;
      margin-top: 4em;
      .col {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 2em;
        border: 2px solid $uiBorderColor;
        border-radius: $contentDefaultIndent / 2;
        + .col {
          margin-left: 1.4em;
          border-color: $uiPrimaryColor;
        }
        img {
          height: 4.4em;
          margin: 0 auto;
        }
        h6 {
          margin-top: 2em;
          font-weight: 500;
        }
        p {
          width: 100%;
          margin: 0;
          margin-bottom: 1em;
        }
        .paymentNotice {
          margin-bottom: 2em;
          padding: 1em;
          border-radius: $contentDefaultIndent;
          background-color: lighten($uiPositiveColor, 55%);
          font-size: 0.9em;
          font-weight: 500;
        }
        .button {
          width: 100%;
          margin: auto;
          margin-top: auto;
          margin-bottom: 0;
          &.talkToSalesButton {
            background: none;
            box-shadow: none;
            color: $uiPrimaryColor;
            font-weight: 500;
            &:hover,
            &:active {
              background-color: $uiBlackColor;
              color: $uiWhiteColor;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .postSignUp {
    .content {
      width: 100%;
      padding: 0;
      .card {
        width: 100%;
        max-width: 100%;
        margin: auto;
        .block {
          max-width: 24rem;
          margin: auto;
          .description {
            p {
              padding: 0;
            }
          }
          .button {
            padding: 0;
          }
        }
      }
      .row {
        flex-direction: column;
        margin: 2em auto;
        .col {
          + .col {
            margin-top: 1.4em;
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .postSignUp {
    .content {
      .card {
        .block {
          padding: 2em 1em;
        }
      }
      .row {
        .col {
          padding: 1em;
          h6 {
            margin-top: 1em;
          }
          .paymentNotice {
            margin-bottom: 1em;
          }
        }
      }
    }
  }
}

body[data-mobile] {
  .postSignUp {
    .content {
      .card {
        box-shadow: none;
      }
    }
  }
}

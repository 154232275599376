@import "nlib/config";

.askClientWindow {
  .dialogClassName {
    width: 33rem;
  }
  .contentClassName {
    overflow: visible !important;
  }
  .inputGroup {
    margin-bottom: $contentDefaultIndent * 2;
    .inputWrap {
      position: relative;
    }
  }
  .text {
    margin-bottom: $contentDefaultIndent * 1.5;
    svg {
      font-size: 1rem;
    }
  }
  .footer {
    flex-wrap: nowrap;
    margin-right: $contentDefaultIndent * -1;
    margin-left: $contentDefaultIndent * -1;
    button {
      padding-right: $contentDefaultIndent * 2;
      padding-left: $contentDefaultIndent * 2;
    }
  }
  .emailErorrText {
    width: 18em;
    color: $uiNegativeColor;
  }
}

[data-mobile] {
  .footer {
    flex-wrap: wrap;
    button {
      width: 100%;
      max-width: 16rem;
      margin: $contentDefaultIndent auto 0;
    }
  }
}

@import "nlib/config";

.selectedBusinessesWindow {
  .logo {
    width: 1.25rem;
    margin-right: $contentDefaultIndent;
  }
  .info {
    margin-bottom: $contentDefaultIndent;
    text-align: center;
    svg {
      font-size: 1rem;
    }
  }
  .content {
    height: 240px;
    .select {
      height: 100%;
    }
    .noDataContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      font-size: 1rem;
      text-align: center;
      svg {
        margin-bottom: $contentDefaultIndent;
        opacity: 0.2;
        font-size: 6em;
      }
    }
  }
}

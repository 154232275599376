@import "config";

.imageViewer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  :global(.ril) {
    &-toolbar {
      background: none;
    }
    &-toolbar-right {
      padding: 0 $contentDefaultIndent * 2;
      border-bottom-left-radius: $contentDefaultIndent * 2;
      background-color: rgba($uiBlackColor, 0.75);
    }
    &-loading-circle {
      display: none;
    }
  }
}

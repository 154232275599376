@import "nlib/config";

.setupBackupWindow {
  width: 420px !important;
  .googleDriveIcon {
    width: 1.25rem;
    margin-right: 0.3em;
  }
  svg {
    font-size: 14px;
  }
  .connectionInfo {
    width: 100%;
    margin-top: $contentDefaultIndent;
    .row {
      margin-top: $contentDefaultIndent / 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .buttonsContainer {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    button {
      + button {
        margin-left: 1rem;
      }
    }
  }
  &.accountConnected {
    .buttonsContainer {
      .googleDrivePicker {
        width: 100%;
      }
    }
  }
}

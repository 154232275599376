@import "config";

.autoCompleteInput {
  flex: auto;
  .caret {
    display: flex;
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    color: $uiDarkColor;
    cursor: pointer;
    &:hover {
      color: $uiBlackColor;
    }
    &.invalid {
      right: 1.25rem;
    }
  }
  button {
    span {
      input {
        &.input {
          padding-right: 0.5rem;
        }
        &.invalid:not([disabled]) {
          padding-right: 1.75rem;
        }
      }
    }
  }
  &.showCaret {
    button {
      span {
        input {
          &.input {
            padding-right: 1.75rem;
          }
          &.invalid {
            padding-right: 2.75rem;
          }
        }
      }
    }
  }
  .dropDownToggle {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    &[disabled] {
      opacity: 1 !important;
    }
  }
  .dropDownMenu {
    width: 100%;
    .addNewItemButton {
      font-weight: 500;
    }
    > div {
      max-height: 11.2em !important;
      > button {
        max-width: none !important;
        > span {
          display: flex;
          .indent {
            display: inline-block;
            width: 1rem;
          }
          > span {
            &:first-child {
              min-width: 0;
              margin-right: auto;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          > .badge {
            display: inline-block;
            flex: none;
            max-width: 40%;
            margin-left: 0.6em;
            overflow: hidden;
            border: 1px solid transparent;
            background-color: $uiDarkColor;
            color: $uiWhiteColor;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .tooltip {
    display: none;
    max-width: 20rem;
    overflow: hidden;
    color: $uiDarkColor;
    word-break: break-word;
  }
  .input:hover + .tooltip {
    display: block;
  }
}

@import "config";

.page {
  display: flex;
  flex: auto;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 0 1em;
  + div {
    padding-right: 0;
  }
  > :global(.card) {
    flex: auto;
    box-shadow: none;
    > :global(.card) {
      &-body {
        flex: none;
        min-height: 60vh;
        padding: 0 0 $contentDefaultIndent * 2;
        :global(.rt) {
          &-table {
            :global(.rt) {
              &-td,
              &-th {
                $verticalPadding: ($contentDefaultIndent + $contentDefaultIndent / 2);
                padding-top: $verticalPadding;
                padding-bottom: $verticalPadding;
              }
            }
          }
        }
      }
    }
  }
}

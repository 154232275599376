@import "nlib/config";

.userSettingsWindow {
  .warning {
    margin-bottom: $contentDefaultIndent * 2;
    font-size: 0.875rem;
    svg {
      color: $uiWarningColor;
      font-size: 1.25rem;
    }
  }
  .label {
    margin-bottom: $contentDefaultIndent / 2;
    font-size: 0.75rem;
    line-height: 1;
  }
  .input {
    margin-bottom: $contentDefaultIndent * 2;
  }
  .buttons {
    display: flex;
    justify-content: center;
    button {
      min-width: 6rem;
      + button {
        margin-left: $contentDefaultIndent;
      }
    }
  }
}
[data-mobile] {
  .languageSelectorDropDown {
    width: 21rem !important;
    max-width: calc(100% - 5rem) !important;
  }
}

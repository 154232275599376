@import "nlib/config";

.filters {
  display: flex;
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  padding: $contentDefaultIndent * 2 0;
  .search {
    flex: 2;
    width: 20px;
    max-width: 370px;
    margin-right: auto;
  }
  .account,
  .type {
    flex: 1;
    width: 10px;
    max-width: 250px;
  }
  .account,
  .type {
    margin-left: $contentDefaultIndent * 2;
  }
}

@import "config";

.dateTimeInputCalendar {
  $headerPaddingTop: 1.2em;
  display: flex !important;
  padding: 0.5em;
  box-shadow: 0 0.5rem 4rem rgba($uiBlackColor, 0.11),
    0 10px 20px rgba($uiBlackColor, 0.05),
    0 2px 3px rgba($uiBlackColor, 0.06);
  > div:not([class$=triangle]) {
    border-radius: 1em;
    box-shadow: none;
    &[class$=month-container] {
      > div:first-child {
        padding-top: $headerPaddingTop;
        color: $uiDarkColor;
        font-size: 1.1em;
        font-weight: 500;
        > div {
          font-size: 1em;
          font-weight: 500;
          &:first-child {
            margin-bottom: $contentDefaultIndent;
          }
          &:last-child {
            padding: $contentDefaultIndent 0 0 0;
          }
        }
      }
      [class$=month-wrapper] {
        display: flex;
        > div {
          flex: 1;
          width: 4em;
          margin: $contentDefaultIndent / 2 $contentDefaultIndent;
          padding: $contentDefaultIndent / 2 $contentDefaultIndent;
          border-radius: $contentDefaultIndent;
          color: $uiDarkColor;
          cursor: pointer;
          &:hover {
            background-color: $uiDarkColor !important;
            color: $uiWhiteColor !important;
          }
          &[class$=--selected] {
            color: $uiWhiteColor;
            font-weight: 500;
          }
          &[class$=--disabled] {
            opacity: $uiComponentDisabledOpacity;
            pointer-events: none;
          }
        }
      }
    }
    &:last-child {
      width: 100%;
      > div:first-child {
        padding-top: $headerPaddingTop;
      }
      > div:last-child {
        width: 100%;
        margin: 0;
        padding: $contentDefaultIndent * 2;
        > div {
          width: 100%;
          [class$=--selected] {
            background-color: $uiHighlightColor;
          }
        }
      }
    }
    > div {
      border-radius: 0.5em;
    }
  }
  :global(.react) {
    &-datepicker__day,
    &-datepicker__month {
      &--in-range,
      &--in-selecting-range {
        background-color: $uiHighlightColor !important;
        color: $uiWhiteColor !important;
      }
    }
    &-datepicker__day {
      &:hover {
        background-color: $uiDarkColor !important;
        color: $uiWhiteColor !important;
      }
    }
  }
  select {
    border-width: 1px;
    border-radius: 3px;
  }
}

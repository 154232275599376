@import "nlib/config";

.userDetailsWindow {
  .contentClassName {
    overflow: visible !important;
  }
  .relative {
    position: relative;
  }
  .row {
    display: flex;
    margin-bottom: $contentDefaultIndent * 2;
    .col {
      flex: 1;
      + .col {
        margin-left: $contentDefaultIndent * 2;
      }
    }
  }
  .hidden {
    overflow: hidden;
  }
  .tooltip {
    color: $uiNegativeColor;
    white-space: nowrap;
    svg {
      font-size: 1.2em;
    }
  }
  .includeExclude {
    height: 208px;
  }
}

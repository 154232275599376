@import "nlib/config";

.selectContactInput {
  display: flex;
  position: relative;
  width: 100%;
  svg {
    z-index: 3;
  }
  &.withButton {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:focus {
        z-index: 2;
      }
    }
  }
  .button {
    z-index: 1;
    min-width: 2.5rem;
    height: 2.5rem;
    margin-left: -1px;
    padding: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    svg {
      font-size: 1rem;
    }
  }
  &.loading::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: preloader 1s linear infinite;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3));
    background-size: 200%;
  }
}
.addNew {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3rem;
  padding: 0 $contentDefaultIndent * 2;
  border-bottom: 1px solid $uiBorderColor;
  color: $uiPrimaryColor;
  font-size: 0.8125rem;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: rgba($uiBlackColor, 0.04);
  }
  .newValue {
    margin-left: $contentDefaultIndent / 2;
    overflow: hidden;
    color: $uiFontColor;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  svg {
    min-width: 1.125rem;
    margin-right: 0.25 * $contentDefaultIndent;
    font-size: 1.125rem;
  }
}
.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1.375rem;
  overflow: hidden;
  .name {
    margin-right: $contentDefaultIndent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .badge {
    flex: none;
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@keyframes preloader {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: -100% 0;
  }
}

@import "nlib/config";

.addQuickBooksDesktopWindow {
  .dialog {
    width: auto;
    .content {
      padding: 0;
    }
  }
  .img {
    display: inline-block;
    width: 1.5rem;
    margin-right: 0.3em;
  }
  .text {
    margin-bottom: $contentDefaultIndent * 2;
  }
}

@import "config";

.reconnectAccountModal {
  p {
    margin: 0;
    padding: 0 0.5rem 1rem;
    font-weight: 500;
    svg {
      font-size: 1rem;
    }
  }
  .bodyClassName {
    width: 40rem;
    padding-right: $contentDefaultIndent * 3;
    padding-left: $contentDefaultIndent * 3;
  }
  .statusCell {
    flex: 10;
    width: 10px;
    .text {
      color: $uiWarningColor;
      font-weight: 500;
      &.negative {
        color: $uiNegativeColor;
      }
    }
  }
  .businessNameCell {
    flex: 10;
    width: 10px;
  }
  .bankNameCell {
    flex: 10;
    width: 10px;
  }
  .businessNameCell,
  .bankNameCell {
    white-space: nowrap;
  }
  .actionCell {
    flex: none;
    justify-content: right;
    width: 80px;
    .reconnectButton {
      padding: 0.25rem;
      border: 1px solid transparent;
      line-height: 1;
      svg {
        color: $uiWhiteColor;
        font-size: 1rem;
      }
    }
  }
}

@import "nlib/config";

.addNewBusiness {
  width: 50rem;
  text-align: left;
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 $contentDefaultIndent * 3 $contentDefaultIndent * 3;
  }
  .progress {
    display: flex;
    margin-bottom: $contentDefaultIndent * 3;
    .bar {
      flex: 1;
      height: $contentDefaultIndent / 2;
      transition: background-color 0.2s;
      border-radius: $uiBorderRadius;
      background-color: $uiBorderColor;
      &.active {
        background-color: $uiPrimaryColor;
      }
      + .bar {
        margin-left: $contentDefaultIndent;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    min-height: 555px;
    .preloader {
      margin: auto;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: $contentDefaultIndent * 3;
    button {
      padding-right: $contentDefaultIndent * 3;
      padding-left: $contentDefaultIndent * 2.5;
    }
  }
}

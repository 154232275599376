@import "config";

.avatar {
  $size: 2em;
  width: $size;
  height: $size;
  overflow: hidden;
  border-radius: $size / 2;
  > div {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    &[data-letters] {
      background-color: rgba($uiNeutralColor, 0.5);
    }
    > img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
    > span {
      display: flex;
      margin: auto;
      color: $uiWhiteColor;
      font-weight: 500;
    }
  }
}

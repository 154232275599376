@import "config";

.card {
  :global(.card) {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $contentDefaultIndent * 2;
      border-bottom: 1px solid $uiBorderColor;
      background-image: $uiHeaderGradient;
    }
    &-body {
      display: flex;
      flex-direction: column;
      padding: $contentDefaultIndent * 2;
    }
  }
}

@import "nlib/config";

.selectServiceBlock {
  .header {
    margin-bottom: $contentDefaultIndent * 2;
    font-size: 1.25rem;
    font-weight: 500;
  }
  .service {
    display: flex;
    align-items: center;
    padding: $contentDefaultIndent * 3;
    transition: transform 0.2s, box-shadow 0.2s;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    &:not(.active):hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    img {
      width: 2rem;
      height: 2rem;
      margin-right: $contentDefaultIndent * 3;
    }
    &[disabled] {
      opacity: 1 !important;
      filter: none !important;
      img,
      span {
        opacity: 0.5;
      }
    }
    .badge {
      margin-left: auto;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      opacity: 1 !important;
      font-size: 14px;
      &.subscriptionBadge {
        background-color: rgba($uiPositiveColor, 0.1);
        color: $uiPositiveColor;
      }
      &.soonBadge {
        background-color: rgba($uiWarningColor, 0.1);
        color: $uiWarningColor;
      }
    }
    &.selected {
      border-color: $uiPrimaryColor;
    }
    + .service {
      margin-top: $contentDefaultIndent * 3;
    }
  }
}

@import "config";

.buttonRadioGroup {
  .button {
    min-width: 3em;
    padding: $contentDefaultIndent;
    line-height: 1.2;
    &:global(.btn) {
      &-light,
      &-outline-light {
        &,
        &:global(.active) {
          color: $uiDarkColor;
        }
      }
    }
  }
}

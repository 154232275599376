@import "nlib/config";

.formRow {
  &.wrap {
    flex-wrap: wrap;
  }
  + .formRow {
    margin-top: $contentDefaultIndent * 2;
  }
}

div[data-compact] {
  .formRow {
    + .formRow {
      margin-top: $contentDefaultIndent * 1.5;
    }
  }
}

@import "nlib/config";

.businessSelector {
  button {
    border: none;
    background-color: $uiBackgroundColor !important;
  }
}
.businessSelector,
.businessSelectorDropDown {
  .listItem {
    display: flex;
    align-items: center;
    height: fit-content;
    .logo {
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
      min-height: 1.5rem;
      margin-right: $contentDefaultIndent / 2;
      border-radius: 50%;
      background-color: $uiWhiteColor;
      img {
        width: 1.5rem;
      }
    }
    .businessStatus {
      min-width: 1.125rem;
    }
    svg {
      font-size: 1rem;
    }
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .addNew {
    .plus {
      margin-right: 0.25em;
      font-size: 1.25rem;
    }
  }
  .businessSearch {
    margin-bottom: $contentDefaultIndent;
  }
}
.businessSelectorDropDown {
  display: flex;
  flex-direction: column;
  width: 25rem;
}

[data-mobile] {
  .businessSelectorDropDown {
    display: flex;
    flex-direction: column;
    min-width: 14rem;
    max-width: 20rem !important;
    > div {
      max-height: none;
    }
  }
}

@import "nlib/config";

.tagsInput {
  .content {
    display: flex;
    flex-wrap: wrap;
    min-height: 2.5rem;
    padding: 5px;
    overflow: hidden;
    border: 1px solid $uiBorderColor;
    border-radius: 4px;
    cursor: text;
  }
  &:hover:not(.focused):not(.disabled) {
    .content {
      background-color: $uiBackgroundColor;
    }
  }
  &.focused {
    .content {
      border-color: $uiPrimaryColor;
    }
  }
  &.disabled {
    .content {
      border-color: #f0f0f0;
      background-color: lighten($uiBorderColor, 5%);
    }
  }
  input {
    flex: 1;
    min-width: 40%;
    height: 1.5rem;
    margin: $contentDefaultIndent / 4;
    padding: 0 $contentDefaultIndent / 2 + 1px;
    border: none;
    outline: none;
    background: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  &.disabled {
    background-color: $uiBackgroundColor;
    .content {
      cursor: default;
    }
  }
  &[disabled] {
    opacity: 1 !important;
  }
  .autocomplete {
    input {
      padding-left: 20px !important;
      + div {
        padding-left: 0;
      }
    }
  }
  .dropdown {
    position: relative;
    width: 100%;
  }
  .iconBefore {
    margin-top: 6px;
    margin-left: 4px;
    font-size: 1rem;
  }
  &.invalid {
    .iconBefore {
      color: $uiNegativeColor;
    }
  }
  .selectDropdownContent {
    width: 100%;
  }
}

.selectDropdownContent {
  padding: $contentDefaultIndent;
  .scroll {
    overflow: auto;
  }
  .groupLabel {
    padding: $contentDefaultIndent / 2 $contentDefaultIndent;
    background-color: $uiBackgroundColor;
    font-size: 0.75rem;
    font-weight: bold;
  }
  .option {
    display: flex;
    overflow: hidden;
    .label {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.selected {
      font-weight: 500;
    }
  }
  .placeholder {
    color: $uiPlaceholderColor;
  }
}

[data-mobile] {
  .tagsInput {
    .content {
      padding: 4px;
      border: 2px solid $uiBorderColor;
      input {
        height: 2rem;
      }
    }
  }
  .selectDropdownContent {
    .option {
      padding-right: $contentDefaultIndent * 2;
      padding-left: $contentDefaultIndent * 2;
      &.selected {
        svg {
          font-size: 1.25rem;
        }
      }
    }
  }
}

@import "nlib/config";

.pairTransactionsWindow {
  .hidden {
    visibility: hidden;
  }
  .modalDialog {
    width: 50rem;
    .contentWrap {
      display: flex;
      position: relative;
      flex: auto;
      flex-direction: column;
      align-items: center;
      height: 24rem;
    }
  }
  .tabs {
    justify-content: center;
    .tab {
      span + span {
        margin-left: 0.3em;
      }
    }
  }
  .preloader {
    margin-top: $contentDefaultIndent * 4;
    margin-bottom: $contentDefaultIndent * 2;
  }
  .table {
    overflow: auto;
    white-space: nowrap;
    [data-table-cell] {
      padding: $contentDefaultIndent;
    }
    svg {
      font-size: 1rem;
    }
    .tableRow {
      min-height: 38px;
      &.highlighted {
        background-color: lighten($uiHighlightColor, 55%);
        font-weight: 500;
      }
    }
    .statusCell {
      flex: 5;
      justify-content: center;
      width: 5px;
      svg {
        font-size: 1.25rem;
      }
      &.toExtract {
        color: $uiWarningColor;
      }
      &.toReconcile {
        color: $uiSpecialColor;
      }
      &.needReaction {
        color: $uiWarningColor;
      }
      &.toReview {
        color: $uiHighlightColor;
      }
      &.toReport {
        color: $uiPositiveColor;
      }
      &.exported {
        color: $uiMediumDarkColor;
      }
      &.excluded {
        color: $uiAccentColor;
      }
      &.error {
        color: $uiNegativeColor;
      }
    }
    .accountCell {
      flex: 14;
      width: 14px;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .amountCell {
      flex: 12;
      justify-content: flex-end;
      width: 12px;
      padding-right: $contentDefaultIndent * 2;
      .amount {
        overflow: hidden;
        color: $uiMediumDarkColor;
        font-size: $uiDesktopFontSize;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.coloredNumber {
          color: $uiPositiveColor;
          &[data-negative] {
            color: $uiNegativeColor;
          }
        }
      }
    }
    .addressCell {
      flex: 14;
      width: 14px;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .timestampCell {
      flex: 9;
      width: 9px;
    }
    .descriptionCell {
      flex: 16;
      width: 16px;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .actionsCell {
      flex: none;
      width: 86px;
      padding-right: 0;
      &.tableBodyCell {
        padding-top: $contentDefaultIndent / 2;
        padding-bottom: $contentDefaultIndent / 2;
      }
    }
  }
  .filters {
    width: 100%;
    padding: $contentDefaultIndent;
    background-color: transparent;
  }
  .pagination {
    margin-top: $contentDefaultIndent * 2;
  }
}

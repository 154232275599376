@import "config";

.fileDropZone {
  width: 100%;
  min-height: 6em;
  margin: $contentDefaultIndent 0;
  padding: 0.5em 1em;
  border: 1px dashed;
  border-radius: 1em;
  color: $uiDarkColor;
  font-size: 1.1em;
  line-height: 1;
  text-align: center;
  word-break: break-word;
  &[data-accepted] {
    color: $uiPositiveColor;
  }
  &[data-rejected] {
    color: $uiNegativeColor;
  }
  &[data-active] {
    color: $uiDarkColor;
  }
  .supportedFormats {
    opacity: 0.8;
    font-size: 0.8em;
  }
}

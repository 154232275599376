@import "nlib/config";

.actionCellContent {
  width: 100%;
  margin-left: -$contentDefaultIndent;
  .button {
    height: 1.5rem;
    padding-right: $contentDefaultIndent / 2;
    padding-left: $contentDefaultIndent / 2;
    background-color: transparent;
    svg {
      min-width: 1rem;
      font-size: 1rem;
      + span {
        margin-left: 0.3em;
      }
      &.colorSuccess {
        color: $uiPositiveColor;
      }
    }
  }
  .dropdown {
    .toggle {
      button {
        height: 1.5rem;
        padding-right: $contentDefaultIndent / 2;
        padding-left: $contentDefaultIndent / 2;
        background-color: transparent;
        font-weight: normal;
        svg {
          min-width: 1rem;
          font-size: 1rem;
        }
      }
    }
  }
  .dropdownContent {
    min-width: 100%;
    .dropdownMenuItem {
      min-height: 1.75rem;
      padding: $contentDefaultIndent / 2 $contentDefaultIndent;
    }
  }
}

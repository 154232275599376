@import "nlib/config";

.menuItem {
  justify-content: flex-start;
  width: 100%;
  font-weight: 500;
  svg {
    min-width: 1rem;
    font-size: 1rem;
  }
  &.primary {
    svg {
      color: $uiPrimaryColor !important;
    }
  }
  &.success {
    svg {
      color: $uiPositiveColor;
    }
  }
  &.negative,
  &.danger {
    svg {
      color: $uiNegativeColor;
    }
  }
  &.warning {
    svg {
      color: $uiWarningColor;
    }
  }
  &.medium {
    svg {
      color: $uiMediumDarkColor;
    }
  }
}

@import "config";

.badge {
  min-width: 2em;
  padding: 0.2em 0.4em;
  border-radius: $contentDefaultIndent;
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  &.counter {
    display: inline-block;
    flex: none;
    max-width: 65%;
    margin-left: $contentDefaultIndent;
    border: 1px solid transparent;
    background-color: $uiDarkColor;
    color: $uiWhiteColor;
    &[data-unread] {
      background-color: $uiNegativeColor;
    }
  }
}

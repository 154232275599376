@import "config";

.comment {
  display: flex;
  align-items: flex-end;
  + .comment {
    margin-top: $contentDefaultIndent * 2;
  }
  .avatar {
    min-width: 2rem;
    margin-right: $contentDefaultIndent;
    font-size: 1rem;
  }
  .content {
    padding: $contentDefaultIndent $contentDefaultIndent * 1.5;
    border-radius: 0.5rem 0.5rem 0.5rem 0;
    background-color: $uiBackgroundColor;
    .info {
      display: flex;
      align-items: center;
      font-size: 0.6875rem;
      .name {
        font-weight: bold;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .name,
      .date,
      .noteType {
        margin-right: $contentDefaultIndent;
        span + span {
          margin-left: 0.6em;
        }
      }
      .date {
        color: $uiMediumDarkColor;
      }
      .status {
        margin-left: auto;
        color: $uiHighlightColor;
      }
    }
    .text {
      .mention {
        color: $uiHighlightColor;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &.note {
    .content {
      background-color: lighten($uiWarningColor, 45);
    }
  }
}

@import "config";
@import "animations";

.zIndexA {
  z-index: 1000 !important;
}

.zIndexB {
  z-index: 2000 !important;
}

.zIndexC {
  z-index: 3000 !important;
}

.hidden {
  visibility: hidden;
}

.overflowHidden {
  overflow: visible !important;
}

.overflowVisible {
  overflow: visible !important;
}

.cursorPointer {
  cursor: pointer;
}

.highlightText {
  color: $uiHighlightColor !important;
}

.negativeText {
  color: $uiNegativeColor !important;
}

.positiveText {
  color: $uiPositiveColor !important;
}

.warningText {
  color: $uiWarningColor !important;
}

.specialText {
  color: $uiSpecialColor !important;
}

.neutralText {
  color: $uiNeutralColor !important;
}

.whiteText {
  color: $uiWhiteColor !important;
}

.lightText {
  color: $uiWhiteColor !important;
}

.mediumDarkText {
  color: $uiMediumDarkColor !important;
}

.darkText {
  color: $uiDarkColor !important;
}

.blackText {
  color: $uiBlackColor !important;
}

.textCenter {
  text-align: center;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex {
  display: flex;
}

.flexCenter {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.flexStart {
  display: flex;
  justify-content: flex-start !important;
}

.flexEnd {
  display: flex;
  justify-content: flex-end !important;
}

.flexNoWrap {
  display: flex;
  flex-wrap: nowrap;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.coloredNumber {
  color: $uiPositiveColor !important;
  &[data-negative] {
    color: $uiNegativeColor !important;
  }
}

.noDataContent {
  margin: auto;
  font-size: 1.4em;
  text-align: center;
  > div:first-child {
    max-width: 12em;
    margin: auto;
    > div:first-child {
      svg {
        margin-bottom: $contentDefaultIndent;
        opacity: 0.2;
        font-size: 6rem;
      }
    }
    button,
    ~ div {
      margin-top: $contentDefaultIndent * 2;
    }
  }
}

.relative {
  position: relative;
}

.wait {
  animation: rotate 2s linear infinite;
  color: $uiNeutralColor;
}

.card {
  padding: $contentDefaultIndent * 2 $contentDefaultIndent * 2.5;
  border-radius: $contentDefaultIndent;
  background-color: $uiWhiteColor;
}

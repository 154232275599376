@import "config";

.actionsButtons {
  button {
    margin-left: 0 !important;
    padding: 0.2em 0.3em;
    &:hover {
      z-index: auto !important;
    }
    &:not(:hover) + button:not(:hover) {
      border-left: 1px solid rgba($uiBlackColor, 0.1);
    }
    svg {
      font-size: 1.2em !important;
    }
  }
}

@import "nlib/config";

.globalNotifications {
  padding-top: $contentDefaultIndent * 1.5;
}
.preloader {
  z-index: 9999;
  background-color: rgba($uiWhiteColor, 0.25);
}

[data-mobile] {
  .globalNotifications {
    padding: $contentDefaultIndent * 1.5 $contentDefaultIndent * 1.5 $contentDefaultIndent * 3;
  }
}

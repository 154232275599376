@import "config";

.autoTooltip {
  display: inline-block;
  position: relative;
  max-width: 100%;
  .content,
  .hoverTooltip {
    box-sizing: content-box;
    max-width: 20em;
    overflow: hidden;
    text-overflow: ellipsis;
    b {
      font-weight: 600;
    }
    hr {
      display: inline-block;
      margin: 0 0.2em;
      border: none;
    }
  }
  .hoverTooltip {
    display: none;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: max-content;
    margin: (-$contentDefaultIndent / 2) (-$contentDefaultIndent);
    padding: $contentDefaultIndent / 2 $contentDefaultIndent;
    transform: translateZ(0);
    border-radius: $contentDefaultIndent;
    background: $uiWhiteColor;
    box-shadow: 0 0.5rem 4rem rgba($uiBlackColor, 0.11),
      0 10px 20px rgba($uiBlackColor, 0.05),
      0 2px 3px rgba($uiBlackColor, 0.06);
    word-break: break-word;
    white-space: pre-line;
    hr {
      display: block;
      margin: 0.5em 0;
    }
  }
  &.active {
    &:hover {
      .content {
        visibility: hidden;
      }
    }
  }
  &:hover {
    .hoverTooltip {
      display: block;
    }
  }
  &:not(:hover) {
    overflow: hidden;
  }
}

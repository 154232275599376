@import "nlib/config";

.selectRpaFrequency {
  display: flex;
  .dropdown {
    flex: auto;
    margin-right: $contentDefaultIndent * 2;
  }
  .toggle {
    display: flex;
    align-items: center;
    height: 2.5rem;
    padding-right: $contentDefaultIndent;
    padding-left: $contentDefaultIndent * 1.5;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    &[data-opened] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &[disabled] {
      opacity: 1 !important;
      background-color: $uiBackgroundColor;
    }
  }
}
.dropDownContent {
  width: 100%;
  margin-top: 0;
  padding: $contentDefaultIndent * 1.5;
  border: 1px solid $uiBorderColor;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: none;
  .option {
    display: flex;
    align-items: center;
    height: 3rem;
    padding-right: $contentDefaultIndent;
    padding-left: $contentDefaultIndent * 1.5;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
    cursor: pointer;
    + .option {
      margin-top: $contentDefaultIndent * 1.5;
    }
    .circle {
      width: 0.875rem;
      height: 0.875rem;
      margin-right: $contentDefaultIndent;
      border: 1px solid $uiBorderColor;
      border-radius: 50%;
    }
    .text {
      margin-right: 0.3em;
      font-weight: 500;
    }
    .icon {
      svg {
        font-size: 1rem;
        pointer-events: none;
      }
    }
    .price {
      margin-left: auto;
    }
    &:hover {
      background-color: $uiBackgroundColor;
    }
    &.active {
      cursor: default;
      .circle {
        border: 3px solid $uiPrimaryColor;
      }
      &:hover {
        background-color: $uiWhiteColor;
      }
    }
  }
}

@import "config";
@import "animations";

$uiPreloaderColor: #0267d4;

.preloader {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($uiWhiteColor, 0.5);
  }
  &.fixed {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: rgba($uiWhiteColor, 0.4);
  }
  > div {
    $size: 4rem;
    position: relative;
    width: $size;
    height: $size;
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    svg {
      animation: rotate 0.8s linear infinite;
      color: $uiPreloaderColor;
      font-size: $size;
    }
  }
  > span {
    margin-left: 0.5em;
    font-size: 2em;
  }
}

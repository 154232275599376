@import "nlib/config";

.superAdminOrganizationInfoBadge {
  display: flex;
  flex: none;
  width: 84px;
  margin-left: $contentDefaultIndent;
  border: 1px solid;
  color: $uiWhiteColor;
  font-size: 0.625rem;
  &[data-mode=active] {
    background-color: lighten($uiPositiveColor, 58%);
    color: $uiPositiveColor;
  }
  &[data-mode=inactive] {
    background-color: lighten($uiNegativeColor, 36%);
    color: $uiNegativeColor;
  }
  &[data-mode=notset] {
    background-color: lighten($uiMediumDarkColor, 38%);
    color: $uiMediumDarkColor;
  }
  &[data-mode=custom] {
    background-color: lighten($uiPrimaryColor, 54%);
    color: $uiPrimaryColor;
  }
  > span {
    flex: 1;
    width: 33.33%;
    + span {
      margin-left: 0.3em;
    }
  }
}

@import "config";

.commentsBlock {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .comments {
    flex: auto;
    min-height: 5rem;
    margin-top: $contentDefaultIndent;
    padding-right: $contentDefaultIndent * 2;
    overflow: auto;
    .comment {
      position: relative;
    }
  }
  .noContent {
    display: flex;
    min-height: 186px;
  }
  .input {
    display: block;
    margin-top: $contentDefaultIndent * 2;
    border: 1px solid $uiNeutralColor;
    border-radius: $contentDefaultIndent / 2;
    background-color: $uiWhiteColor;
    cursor: text;
    &.focused {
      border-color: $uiHighlightColor;
    }
    .autoCompleteInput {
      cursor: initial;
      textarea {
        height: 4rem;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        border: none;
        background-color: transparent;
        background-color: $uiWhiteColor !important;
        box-shadow: none !important;
        resize: none;
      }
      :global(.dropdown) {
        &-menu {
          width: auto;
          max-width: 100%;
        }
      }
    }
    .typeSelector {
      display: flex;
      width: fit-content;
      margin-left: $contentDefaultIndent;
      > div {
        padding: $contentDefaultIndent $contentDefaultIndent / 2;
        line-height: 1;
        &:not(.active) {
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            opacity: 0.75;
          }
        }
        &.active {
          color: $uiHighlightColor;
          cursor: default;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-right: $contentDefaultIndent * 1.5;
      margin-bottom: $contentDefaultIndent;
      button {
        min-width: 5.5rem;
        margin-left: $contentDefaultIndent;
        &[disabled] {
          pointer-events: initial;
        }
      }
    }
  }
  &.typeNote {
    .input {
      background-color: lighten($uiWarningColor, 45);
      .autoCompleteInput {
        textarea {
          background-color: lighten($uiWarningColor, 45) !important;
        }
      }
    }
  }
  .label {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    .avatar {
      flex: none;
      margin-right: $contentDefaultIndent;
      font-size: 0.75rem;
    }
    span + span {
      margin-left: $contentDefaultIndent;
      color: lighten($uiMediumDarkColor, 20);
    }
  }
}

@import "config";

.tagsInput {
  + div {
    &:global(.tags) {
      &-input {
        display: flex;
        flex: 1;
        flex-flow: row wrap;
        align-items: center;
        padding: $contentDefaultIndent / 4 $contentDefaultIndent;
        font-size: 1em;
        &:global(.is) {
          &-invalid {
            box-shadow: none;
            &:not([disabled]) {
              background-color: lighten(mix($uiAccentColor, $uiWarningColor), 38%) !important;
            }
          }
        }
        :global(.tag) {
          display: flex;
          align-items: center;
          margin: $contentDefaultIndent / 4 0;
          margin-right: $contentDefaultIndent / 2;
          padding: $contentDefaultIndent / 2;
          overflow: hidden;
          transition: transform 0.1s ease, background-color 0.1s ease;
          transition: none;
          border-radius: $contentDefaultIndent / 2;
          background-color: $uiWarningColor;
          color: $uiBlackColor;
          font-size: 0.8em;
          font-weight: normal;
          word-break: break-word;
          cursor: pointer;
          user-select: none;
          &:hover,
          &:global(.selected) {
            background-color: $uiNegativeColor;
            color: $uiWhiteColor;
          }
          &:global(.dupe) {
            transform: scale3d(1.2, 1.2, 1.2);
            background-color: $uiNegativeColor;
          }
          &::after {
            content: "×";
            position: relative;
            padding-left: 0.2em;
            font-size: 1.5em;
            line-height: 1em;
          }
        }
        > input {
          flex: 1;
          height: auto !important;
          margin: $contentDefaultIndent / 4 0;
          padding: 0 !important;
          transition: none;
          border: none;
          background-color: transparent;
          box-shadow: none;
          &:global(.is) {
            &-invalid {
              background-image: none;
              &::-webkit-calendar-picker-indicator {
                display: none !important;
              }
            }
          }
          &:-webkit-autofill {
            &,
            &:hover,
            &:focus,
            &:active {
              box-shadow: 0 0 0 3em $uiWhiteColor inset !important;
            }
          }
        }
      }
    }
    &[disabled] {
      cursor: not-allowed;
      :global(.tag) {
        pointer-events: none !important;
      }
    }
  }
}

@import "config";

.modalWindowContainer {
  display: flex !important;
  overflow: auto;
  color: $uiDarkColor;
  font-size: $uiDesktopFontSize;
  .modalWindow {
    flex-shrink: 0;
    margin: auto;
    padding: $contentDefaultIndent;
    &.appModalWindow {
      &:global(.modal) {
        &-sm {
          max-width: 30em;
        }
      }
    }
    &:not(.appModalWindow) {
      max-width: none;
    }
    .modalHeader {
      padding: $contentDefaultIndent $contentDefaultIndent * 2;
      border-radius: $contentDefaultIndent $contentDefaultIndent 0 0;
      background-image: $uiHeaderGradient;
      backdrop-filter: blur(6px);
      .modalTitle {
        display: flex;
        align-items: center;
        width: 100%;
        line-height: 1;
        word-break: break-word;
        svg {
          min-width: 20px;
        }
        img {
          height: 1em;
          + span {
            margin-left: 0.3em;
          }
        }
        .text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .closeButton {
          margin-left: auto;
          padding-left: $contentDefaultIndent;
          font-size: 1.4em;
          cursor: pointer;
          &:hover {
            color: $uiHighlightColor;
          }
        }
      }
    }
    .modalBody {
      padding-top: 1.4em;
      padding-bottom: 1.4em;
      pre {
        word-break: break-word;
      }
    }
    .modalFooter {
      justify-content: center;
      padding: $contentDefaultIndent $contentDefaultIndent * 2;
      border-radius: 0 0 $contentDefaultIndent $contentDefaultIndent;
      background-color: $uiWhiteColor;
    }
  }
  [role=button],
  button {
    font-weight: normal !important;
    user-select: none;
  }
  input,
  textarea,
  select,
  button {
    font-size: $uiDesktopFontSize !important;
  }
}

.modalBackdrop {
  opacity: 0.3 !important;
}

:global(.introjs) {
  &-fixParent {
    &.modalWindowContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      height: auto;
      margin: auto;
      overflow: visible;
      transition: none;
      * {
        transition: none !important;
      }
    }
  }
}

body:not([data-introjs]) {
  &.overflowX {
    overflow-x: hidden;
  }
  &.overflowY {
    height: 100vh;
    overflow-y: hidden;
  }
}

@media (max-width: 575px) {
  .modalWindowContainer {
    .modalWindow {
      &.appModalWindow {
        max-width: 512px;
      }
    }
  }
}

@import "nlib/config";

.settingsDropDown {
  .toggle {
    display: flex;
    .avatarContainer {
      position: relative;
      .avatar {
        > span {
          cursor: pointer;
        }
      }
    }
  }
  .dropdownContent {
    width: auto;
    min-width: 14rem;
    max-width: 18rem;
  }
}
.dropdownContent {
  min-width: 14rem;
  max-width: 18rem;
  max-height: 26rem;
  padding: $contentDefaultIndent;
  overflow-x: hidden;
  svg {
    font-size: 1rem;
  }
  .name {
    overflow: hidden;
    color: $uiMediumDarkColor;
    text-overflow: ellipsis;
  }
  .active {
    font-weight: 500;
    div {
      flex: auto;
    }
    svg {
      margin-left: $contentDefaultIndent;
      color: $uiPrimaryColor;
    }
  }
  .organizations,
  .languages {
    display: none;
    overflow: hidden;
    background-color: $uiWhiteColor;
  }
  &.organizationSwitcherShown {
    .organizations {
      display: block;
    }
    .mainContent {
      display: none;
    }
  }
  &.languageSwitcherShown {
    .languages {
      display: block;
    }
    .mainContent {
      display: none;
    }
  }
  .organizationLabel {
    flex: auto;
    margin-right: $contentDefaultIndent;
    margin-left: 0.3em;
    overflow: hidden;
    line-height: 1;
    text-overflow: ellipsis;
    span {
      display: block;
      font-size: 0.6875rem;
    }
  }
  .menuItem {
    > div {
      flex: auto;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      min-width: 1rem;
      margin-left: $contentDefaultIndent;
      color: $uiPrimaryColor;
    }
    &.selected {
      font-weight: 500;
    }
  }
  .search {
    margin-top: $contentDefaultIndent;
    margin-bottom: $contentDefaultIndent;
  }
  .nothingFound {
    padding: $contentDefaultIndent;
    color: $uiNeutralColor;
  }
  .deleteAllInactiveButton {
    width: 100%;
  }
}
body:not([data-mobile]) {
  .dropdownContent {
    &.organizationSwitcherShown {
      &.superAdmin {
        min-width: 20rem !important;
      }
    }
  }
}
[data-mobile] {
  .dropdownContent {
    .backButton {
      width: 100%;
      margin: 0 $contentDefaultIndent * -1;
      padding: $contentDefaultIndent;
    }
    .mainContent {
      padding: 0 $contentDefaultIndent $contentDefaultIndent;
    }
    .organizations,
    .languages {
      padding: 0 $contentDefaultIndent * 2 $contentDefaultIndent;
      .menuItem {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

@import "config";

.avatarWithName {
  display: flex;
  align-items: center;
  > div {
    + div {
      margin-left: $contentDefaultIndent;
    }
  }
  .content {
    line-height: 1;
    &[data-left] {
      text-align: right;
    }
    .subContent {
      padding-top: $contentDefaultIndent / 2;
    }
  }
}

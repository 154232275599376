@import "config";

.form {
  $disabledControlColor: lighten($uiBackgroundColor, 2%);
  input:not([type=file]):not([type=radio]):not([type=checkbox]),
  textarea,
  select {
    padding-right: $contentDefaultIndent;
    padding-left: $contentDefaultIndent;
    &:not(:disabled) {
      &:hover,
      &:focus,
      &:active {
        position: relative;
        z-index: 1;
      }
    }
    &:global(.is) {
      &-invalid,
      &-valid {
        padding-right: 2em;
        border-color: $uiBorderColor;
        box-shadow: none;
        &:hover {
          z-index: 100 !important;
        }
      }
      &-invalid {
        &:not(:disabled) {
          background-color: lighten(mix($uiAccentColor, $uiWarningColor), 38%) !important;
          &:hover {
            border-color: $uiNegativeColor !important;
          }
        }
      }
      &-valid {
        &:not(:disabled) {
          background-color: lighten($uiPositiveColor, 55%) !important;
          &:hover {
            border-color: $uiPositiveColor;
          }
        }
      }
    }
  }
  input::placeholder,
  textarea::placeholder,
  select[placeholder] {
    color: darken($uiNeutralColor, 6%);
  }
  select option {
    color: $uiDarkColor;
  }
  input:disabled::placeholder,
  textarea:disabled::placeholder,
  select:disabled[placeholder] {
    color: $disabledControlColor !important;
    user-select: none;
    -webkit-text-fill-color: $disabledControlColor;
  }
  input {
    &::-webkit-calendar-picker-indicator {
      flex: none;
    }
  }
  textarea {
    resize: none;
  }
  select {
    padding-right: 2em;
  }
  :global(.form) {
    &-group {
      margin-bottom: 0;
      + div {
        margin-top: 0.8em;
      }
    }
    &-row {
      flex: auto;
      width: 100%;
      + div {
        margin-top: 0.8em;
      }
      :global(.col) {
        display: flex;
        justify-content: center;
        padding: 0 0.4em;
      }
    }
    &-control {
      border-color: $uiBorderColor;
      color: $uiDarkColor;
      &:focus {
        box-shadow: none;
      }
      &:disabled,
      &[disabled] {
        opacity: 1 !important;
        background-color: $disabledControlColor;
        background-image: none;
        color: $uiDarkColor;
        pointer-events: auto;
        -webkit-text-fill-color: $uiDarkColor;
        &:hover,
        &:focus {
          border-color: $uiBorderColor;
        }
      }
      &:not(:disabled):not([disabled]) {
        &:hover,
        &:active,
        &:focus {
          border-color: $uiNeutralColor;
        }
      }
    }
  }
  :global(.input) {
    &-group-prepend,
    &-group-append {
      > span {
        display: inline-block;
        padding: $contentDefaultIndent;
        overflow: hidden;
        border-color: $uiBorderColor;
        background-image: linear-gradient(to bottom, $uiWhiteColor, $uiHeaderColor);
        color: lighten($uiDarkColor, 20%);
        font-weight: normal;
        text-align: left;
        text-overflow: ellipsis;
      }
    }
    &-group {
      &-text {
        font-size: 1em;
        > span {
          display: flex;
          align-items: center;
          height: 100%;
          overflow: hidden;
          > span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      select:global(.form),
      input:global(.form) {
        &-control {
          height: 100%;
          &:not([size]):not([multiple]) {
            height: 100%;
          }
        }
      }
    }
  }
  :global(.custom) {
    &-toggle {
      display: inline-flex;
      align-items: center;
      height: 1.75rem;
    }
    &-select {
      &:global(.is) {
        &-invalid {
          background-position: center right 1.75rem, right 0.5rem center;
        }
      }
    }
    &-control {
      &:global(custom) {
        &-checkbox {
          cursor: default;
        }
      }
    }
  }
  :global(.react) {
    &-datepicker {
      &-wrapper {
        flex: 1;
        height: 100%;
        > div {
          display: block;
          height: 100%;
          > input {
            height: 100%;
          }
        }
      }
      &-popper {
        z-index: 1100;
      }
    }
  }
}
:global(.custom) {
  &-control {
    &:global(.custom) {
      &-checkbox {
        cursor: default;
      }
    }
  }
}

@import "nlib/config";

.alert {
  display: flex;
  position: relative;
  align-items: center;
  transition: box-shadow 0.2s;
  transition: box-shadow 300ms ease-out;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  font-weight: 500;
  .content {
    display: flex;
    flex: auto;
    align-items: center;
    min-height: 3rem;
    padding: $contentDefaultIndent $contentDefaultIndent * 2;
    border-radius: $uiBorderRadius;
    .icon {
      margin-right: 0.3em;
      font-size: 1rem;
    }
    .message {
      flex: auto;
      white-space: pre-wrap;
    }
    .action {
      margin-left: $contentDefaultIndent * 2;
    }
  }
  &.clickable {
    cursor: pointer;
    &:hover {
      box-shadow: 1px 1px 6px rgba($uiBlackColor, 0.08);
    }
  }
  &.error {
    .content {
      box-shadow: inset 3px 0 0 $uiNegativeColor;
      .icon {
        color: $uiNegativeColor;
      }
    }
  }
  &.warning {
    .content {
      box-shadow: inset 3px 0 0 $uiWarningColor;
      .icon {
        color: $uiWarningColor;
      }
    }
  }
  &.info {
    .content {
      box-shadow: inset 3px 0 0 $uiPrimaryColor;
      .icon {
        color: $uiPrimaryColor;
      }
    }
  }
  &.success {
    .content {
      box-shadow: inset 3px 0 0 $uiPositiveColor;
      .icon {
        color: $uiPositiveColor;
      }
    }
  }
}

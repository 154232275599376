@import "nlib/config";

.excludedWords {
  display: flex;
  position: relative;
  .tooltip {
    width: 230px;
    text-align: center;
  }
  .input {
    flex: auto;
    max-width: 416px;
    margin-right: $contentDefaultIndent * 2;
  }
}

@import "config";

$highlightRowColor: lighten($uiHighlightColor, 46%);
$warningRowColor: lighten($uiWarningColor, 46%);
$positiveRowColor: lighten($uiPositiveColor, 52%);
$negativeRowColor: lighten($uiNegativeColor, 54%);
$specialRowColor: lighten($uiSpecialColor, 35%);
$hoverRowColor: lighten($uiNeutralColor, 16%);

.table {
  display: flex;
  flex: auto 1;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-radius: $contentDefaultIndent / 2;
  background-color: $uiWhiteColor;
  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: $contentDefaultIndent;
    border-top: 1px solid $uiBorderColor;
    .prev,
    .next {
      flex: 2;
    }
    button {
      display: block;
      width: 100%;
      height: auto;
      padding: 0.45rem 1rem;
      border: none;
      border-radius: 0.35rem;
      background-color: $uiDarkColor;
      color: $uiWhiteColor;
      user-select: none;
      &:hover {
        background-color: $uiHighlightColor !important;
      }
    }
    .paginator {
      display: flex;
      flex-grow: 8;
      align-items: center;
      justify-content: center;
      input,
      select {
        margin: 0 0.3em;
        padding: 5px 7px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        outline-width: 0;
        background: #fff;
        font-size: inherit;
        font-weight: normal;
      }
      input {
        width: 70px;
        text-align: center;
      }
    }
  }
}

.tableRow {
  display: flex;
  &.highlight {
    background-color: $highlightRowColor !important;
    font-weight: 500;
  }
  &.warning {
    background-color: $warningRowColor !important;
    font-weight: 500;
  }
  &.positive {
    background-color: $positiveRowColor !important;
    font-weight: 500;
  }
  &.negative {
    background-color: $negativeRowColor !important;
    font-weight: 500;
  }
  &.special {
    background-color: $specialRowColor !important;
    font-weight: 500;
  }
  &:last-child {
    .tableCell {
      &:first-child {
        border-bottom-left-radius: $contentDefaultIndent / 2;
      }
      &:last-child {
        border-bottom-right-radius: $contentDefaultIndent / 2;
      }
    }
  }
}

.tableCell {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  padding: $contentDefaultIndent;
}

.tableHead {
  display: flex;
  padding-top: $contentDefaultIndent * 1.5;
  padding-bottom: $contentDefaultIndent * 1.5;
  overflow: hidden;
  border-bottom: $uiBorderColor;
  background-image: $uiHeaderGradient;
  color: $uiDarkColor;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  > div {
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.active {
    box-shadow: inset 0 3px 0 0 $uiHighlightColor;
    &.desc {
      box-shadow: inset 0 -3px 0 0 $uiHighlightColor;
    }
  }
  &.sortable {
    cursor: pointer;
  }
  &:first-child {
    border-top-left-radius: $contentDefaultIndent / 2;
  }
  &:last-child {
    border-top-right-radius: $contentDefaultIndent / 2;
  }
}

[data-borderless] {
  .tableCell {
    border: none;
  }
  .tableHead {
    border-bottom: 1px solid $uiBorderColor;
  }
}

.table {
  &:not(.flat) {
    .tableRow {
      &:not([disabled]):hover {
        background-color: $hoverRowColor !important;
      }
      &:nth-child(2n) {
        background-color: $uiHeaderColor;
        &.highlight {
          background-color: darken($highlightRowColor, 1%) !important;
        }
        &.warning {
          background-color: darken($warningRowColor, 2%) !important;
        }
        &.positive {
          background-color: darken($positiveRowColor, 2%) !important;
        }
        &.negative {
          background-color: darken($negativeRowColor, 0.5%) !important;
        }
        &.special {
          background-color: darken($specialRowColor, 1%) !important;
        }
      }
    }
  }
  &.flat {
    .tableHead {
      background: $uiWhiteColor;
      &.sortable {
        > div {
          position: relative;
          padding-right: $contentDefaultIndent * 1.25;
          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            border-style: solid;
          }
          &::before {
            transform: translate(0, -5px);
            border-width: 0 3px 3px;
            border-color: transparent transparent $uiDarkColor;
          }
          &::after {
            transform: translate(0, 1px);
            border-width: 3px 3px 0;
            border-color: $uiDarkColor transparent transparent;
          }
        }
        &.active {
          box-shadow: none;
          > div {
            &::before {
              border-color: transparent transparent $uiNeutralColor;
            }
            &::after {
              border-color: $uiHighlightColor transparent transparent;
            }
          }
        }
        &.desc {
          box-shadow: none;
          > div {
            &::before {
              border-color: transparent transparent $uiHighlightColor;
            }
            &::after {
              border-color: $uiNeutralColor transparent transparent;
            }
          }
        }
      }
    }
    .tableRow {
      background: none;
      + .tableRow {
        border-top: 1px solid lighten($uiBorderColor, 5%);
      }
    }
  }
}

@import "nlib/config";

.editBusinessSettingsWindow {
  .title {
    display: flex;
    align-items: center;
  }
  .logo {
    width: 1.25rem;
    margin-right: 0.3em;
    vertical-align: middle;
  }
  .container {
    display: flex;
    min-height: 16rem;
    .sidebar {
      width: 10rem;
      border-right: 1px solid $uiBorderColor;
      .tab {
        display: flex;
        position: relative;
        align-items: center;
        height: 2.75rem;
        padding-left: 1.5rem;
        cursor: pointer;
        user-select: none;
        &:hover {
          background-color: $uiBackgroundColor;
        }
        &.active {
          background-color: $uiBackgroundColor;
          font-weight: 500;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 3px;
            border-radius: 0 3px 3px 0;
            background-color: $uiPrimaryColor;
          }
        }
      }
    }
    .content {
      flex: auto;
      width: 384px;
      padding: 0.75rem 1.5rem 0;
      .label {
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
      .block {
        margin-bottom: 1.5rem;
      }
      .row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        .col {
          flex: 1;
          width: 1px;
          + .col {
            margin-left: 1rem;
          }
        }
      }
    }
  }
  .contentClassName {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .dialogClassName {
    min-width: 34rem;
    &.rpaMode {
      width: 42rem;
      .container {
        min-height: 30rem;
      }
    }
  }
  .tooltip {
    color: $uiNegativeColor;
    font-weight: 500;
    white-space: nowrap;
    svg {
      font-size: 14px;
    }
  }
  .relative {
    position: relative;
  }
  .tagsInput {
    width: 100%;
    max-width: 100%;
  }
}

@import "config";

.dropDownSelect {
  .toggle {
    &[data-caret] {
      &::after {
        $size: 0.3em;
        content: "";
        display: inline-block;
        margin-left: 0.5em;
        border-top: $size solid;
        border-right: $size solid transparent;
        border-bottom: 0;
        border-left: $size solid transparent;
      }
      &[data-split] {
        position: relative;
        z-index: 1;
        &::after {
          margin-left: 0;
        }
      }
    }
    &:global(.btn) {
      &-light,
      &-outline-light {
        color: $uiDarkColor;
      }
    }
  }
  .menu {
    padding: $contentDefaultIndent;
    border: none;
    background: $uiWhiteColor;
    &.dropUpMenu {
      margin-top: $contentDefaultIndent;
    }
    &:not(.dropUpMenu) {
      margin-bottom: $contentDefaultIndent;
    }
    > div {
      max-height: 60vh;
      overflow: auto;
      .item {
        max-width: 34em;
        padding: $contentDefaultIndent;
        overflow: hidden;
        color: $uiDarkColor;
        text-overflow: ellipsis;
        &.active {
          background: darken($uiBackgroundColor, 3%);
        }
        &[data-multiple] {
          > svg {
            font-size: 1.2em;
            + span {
              margin-left: 0.6em;
            }
          }
        }
        &:focus:not(:hover) {
          background: none;
        }
      }
    }
  }
}

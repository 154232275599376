@import "nlib/config";
@import "animations";

.businessStatus {
  display: flex;
  align-items: center;
  height: 1.25rem;
  overflow: hidden;
  font-size: 0.75rem;
  svg {
    font-size: 0.875rem;
  }
  [data-wait] {
    animation: rotate 1s linear infinite;
  }
  .settingUp {
    svg {
      color: $uiHighlightColor;
    }
  }
  .warning {
    color: $uiWarningColor;
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.clickable {
  cursor: pointer;
}

@import "config";

.table {
  border: none;
  :global(.rt) {
    &-table {
      overflow: visible;
      :global(.rt) {
        &-thead,
        &-tfoot {
          box-shadow: none;
        }
        &-thead {
          min-height: 2.6em;
          border-bottom: 1px solid $uiBorderColor;
          background-image: $uiHeaderGradient;
          font-weight: 500;
        }
        &-tbody {
          overflow: visible;
        }
        &-tfoot {
          border-top: 1px solid $uiBorderColor;
          background-color: $uiHeaderColor;
          font-size: 1.1em;
          font-weight: 500;
        }
        &-td,
        &-th {
          display: flex;
          align-items: center;
          padding: $contentDefaultIndent $contentDefaultIndent / 2;
          border: none;
          &:first-child {
            padding-left: $contentDefaultIndent * 2;
          }
          &:last-child {
            padding-right: $contentDefaultIndent * 2;
          }
          > span,
          > a {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &-tr {
          &-group {
            $highlightRowColor: lighten($uiHighlightColor, 46%);
            $warningRowColor: lighten($uiWarningColor, 46%);
            $positiveRowColor: lighten($uiPositiveColor, 52%);
            $negativeRowColor: lighten($uiNegativeColor, 54%);
            $specialRowColor: lighten($uiSpecialColor, 35%);
            $hoverRowColor: lighten($uiNeutralColor, 16%);
            border-color: $uiHeaderColor;
            &:nth-child(2n) {
              > [role=row] {
                background-color: $uiHeaderColor;
                &[data-highlight] {
                  background-color: darken($highlightRowColor, 1%) !important;
                }
                &[data-positive] {
                  background-color: darken($positiveRowColor, 2%) !important;
                }
                &[data-warning] {
                  background-color: darken($warningRowColor, 2%) !important;
                }
                &[data-negative] {
                  background-color: darken($negativeRowColor, 0.5%) !important;
                }
                &[data-special] {
                  background-color: darken($specialRowColor, 1%) !important;
                }
              }
            }
            &:nth-child(2):last-child {
              > [role=row] {
                background: none;
              }
            }
            > [role=row] {
              &[data-highlight] {
                background-color: $highlightRowColor !important;
                font-weight: 500;
              }
              &[data-positive] {
                background-color: $positiveRowColor !important;
                font-weight: 500;
              }
              &[data-warning] {
                background-color: $warningRowColor !important;
                font-weight: 500;
              }
              &[data-negative] {
                background-color: $negativeRowColor !important;
                font-weight: 500;
              }
              &[data-special] {
                background-color: $specialRowColor !important;
                font-weight: 500;
              }
              &:not([disabled]):hover {
                background-color: $hoverRowColor !important;
              }
            }
          }
        }
        &-th {
          justify-content: center;
          > div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:global(.-sort) {
            &-asc {
              box-shadow: inset 0 3px 0 0 $uiHighlightColor;
            }
            &-desc {
              box-shadow: inset 0 -3px 0 0 $uiHighlightColor;
            }
          }
        }
      }
    }
    &-td {
      svg {
        font-size: 1.2em;
      }
      button {
        svg {
          font-size: 1em;
        }
      }
    }
  }
  :global(.pagination) {
    &-bottom {
      :global(.-pagination) {
        padding: $contentDefaultIndent;
        border-top: 1px solid $uiBorderColor;
        box-shadow: none;
        button {
          height: auto;
          padding: 0.45rem 1rem;
          border-radius: 0.35rem;
          background-color: $uiDarkColor;
          color: $uiWhiteColor;
          user-select: none;
          &:hover {
            background-color: $uiHighlightColor !important;
          }
        }
        :global(.-center) {
          flex-grow: 8;
          justify-content: center;
        }
      }
    }
  }
  .squareCell {
    justify-content: flex-start !important;
    label {
      width: 20px;
      padding-left: 1.25rem;
    }
  }
  .statusCell {
    position: relative;
    align-items: center;
    padding: 0 $contentDefaultIndent;
    [data-status] {
      position: relative;
      width: 100%;
      text-align: center;
      &[data-status=toExtract] {
        svg {
          color: $uiWarningColor;
        }
      }
      &[data-status=toReconcile] {
        svg {
          color: $uiSpecialColor;
        }
      }
      &[data-status=needReaction] {
        svg {
          color: $uiWarningColor;
        }
      }
      &[data-status=toReview] {
        svg {
          color: $uiHighlightColor;
        }
      }
      &[data-status=toReport] {
        svg {
          color: $uiPositiveColor;
        }
      }
      &[data-status=exported] {
        svg {
          color: $uiMediumDarkColor;
        }
      }
      &[data-status=excluded] {
        svg {
          color: $uiAccentColor;
        }
      }
      &[data-status=error] {
        svg {
          color: $uiNegativeColor;
        }
      }
      &[data-percents],
      &[data-badge] {
        svg {
          margin-top: -($contentDefaultIndent * 2);
        }
        span {
          position: absolute;
          bottom: -$contentDefaultIndent * 0.75;
          left: 50%;
          margin: 0;
          transform: translateX(-50%);
          border: 1px solid transparent;
        }
      }
      &[data-badge] {
        span {
          max-width: 80%;
          overflow: hidden;
          color: $uiWhiteColor;
          text-overflow: ellipsis;
          text-transform: lowercase;
          &[data-theme=primary] {
            background-color: $uiHighlightColor;
          }
          &[data-theme=warning] {
            background-color: $uiWarningColor;
          }
          &[data-theme=danger] {
            background-color: $uiNegativeColor;
          }
        }
      }
      svg {
        font-size: 1.8em;
      }
    }
  }
  .flagCell {
    $width: 1.8em;
    $height: 1.5em;
    img {
      max-width: $width;
      max-height: $height;
    }
    div {
      min-width: $width;
      height: $height;
      padding: 0 0.2em;
      border: 1px solid;
      border-radius: $contentDefaultIndent / 2;
      > span {
        font-size: 0.9em;
        font-weight: 500;
      }
    }
  }
  .addressCell,
  .linkCell {
    > span,
    a {
      &[data-inactive] {
        text-decoration: none;
      }
      &:not([data-inactive]) {
        cursor: pointer;
        &:hover {
          color: $uiHighlightColor;
          text-decoration: none;
        }
      }
    }
    a {
      color: $uiDarkColor;
    }
  }
  .linkCell {}
  .tagsCell {
    display: flex;
    max-width: 100%;
    span {
      padding: $contentDefaultIndent / 2;
      overflow: hidden;
      border-radius: $contentDefaultIndent / 2;
      background-color: $uiWarningColor;
      color: $uiBlackColor;
      font-size: 0.8em;
      text-overflow: ellipsis;
      &[data-rest] {
        flex: none;
        background-color: $uiDarkColor;
        color: $uiWhiteColor;
      }
      + span {
        margin-left: $contentDefaultIndent / 2;
      }
    }
  }
  .uploadedByCell {
    overflow: visible;
  }
  .notificationsCell {
    > span,
    > a {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $uiHighlightColor;
      }
    }
    > a {
      color: $uiDarkColor;
    }
  }
  .previewCell {
    min-width: 74px;
    padding-top: $contentDefaultIndent / 2 !important;
    padding-bottom: $contentDefaultIndent / 2 !important;
    overflow: visible;
  }
}
